import React, { useState} from 'react';
import axios from 'axios';
import './pay.css';
import { validatePassword } from '../validator';
import phonepe from '../Images/phonepe-logo.png.png'

const baseurl = "https://stirred-simply-stud.ngrok-free.app";

const Phonepe = () => {
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [amount, setAmount] = useState("");
  const [remark, setRemark] = useState("");

  const userPay = async (e) => {
    e.preventDefault();
    try {
      const dt = new Date();
      const txnid = 'TX' + dt.getFullYear() + (dt.getMonth() + 1) + dt.getDate() + Date.now();
      // Temporary need to generate from user
      const muid = 'MUID' + Math.floor(Math.random(3) * 1000) + Date.now();
      const data = {
        name: name,
        number: number,
        email: email,
        amount: amount,
        transactionId: txnid,
        merchantUserId: muid,
        remark: remark
      };
      const response = await axios.post(`${baseurl}/pay`, data);
      if (response.status === 200 && response.data.success) {
        window.location.href = response.data.data.instrumentResponse.redirectInfo.url
       // navigate(response.data.data.instrumentResponse.redirectInfo.url)
        //window.open(response.data.data.instrumentResponse.redirectInfo.url);
      } else {
        //window.open(response);
      }
    } catch (err) {
      return;
    }
  }

  return (
    <html>
      <body className='main-pe-cont'>
          <div>
            <img width={300} src={phonepe} alt="" />
            <h2 className='h2-pi'><span className='h2-span-pi'>Phone</span> Pe</h2>
          </div>
          <form className="form-main" onSubmit={userPay} action='POST'>
            <div className="form-main-div">
              <input value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder="name" id="pe-nam" name="name" required />
              <input value={number} onChange={(e) => setNumber(e.target.value)} type="text" placeholder="text" id="pe-num" name="text" required />
              <input value={email} onChange={(e) => setEmail(e.target.value)} type="text" placeholder="email" id="pe-em" name="email" required />
              <input value={amount} onChange={(e) => setAmount(e.target.value)} type="text" placeholder="amount" id="pe-am" name="amount" required />
              <input value={remark} onChange={(e) => setRemark(e.target.value)} type="text" placeholder="remark" id="pe-rm" name="remark" />
              <button id="b1001" type="submit">Pay Now</button>
            </div>
          </form>
      </body>
    </html>
  )
}

export default Phonepe;