import React from "react";

const TeamMaahaul = () => {
    return (
        <htm>
            <body>
                <div>
                    Team Maahaul
                </div>
            </body>
        </htm>
    );
}


export default TeamMaahaul;