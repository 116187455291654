import React, { useState } from 'react';
import './investment.css';
import qrcode from '../Images/phonepe-qrcode.png'
import { Link } from "react-router-dom";
import Footer from '../Homes/Footer';
import Header from '../Homes/Header';

const FaqItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="faq-item">
      <div className="faq-question" onClick={toggleOpen}>
        {question}
        <span className={`icon ${isOpen ? 'open' : 'closed'}`}>&#x25BC;</span>
      </div>
      {isOpen && <div className="faq-answer">{answer}</div>}
    </div>
  );
};

const Investment = () => {
  const faqData = [
    {
      question: 'Why should one invest?',
      answer: '',
    },
    {
      question: 'Why do we require investment?',
      answer: '',
    },
    {
      question: 'What future benefits might you receive after making a investment?',
      answer: '',
    },
    {
      question: 'What is the maximum loss you would incur if you invest to us?',
      answer: '',
    },
  ];

  return (
    <html>
      <Header />
      <div className='main-invest-cont'>
        <div className="faq-container">
          <h1>Investment</h1>
          {faqData.map((item, index) => (
            <FaqItem key={index} question={item.question} answer={item.answer} />
          ))}
        </div>
        <div className='qr-container'>
          <h1>Payment through API</h1>
          <Link to="/pay" className="">Payment Link</Link>
          <p>In case of payment through third party payment gatway api extra charges will be applicable</p>
          <h1>Pay through UPI</h1>
          <div className='qr-code'>
            <img className='qr-img' src={qrcode} />
          </div>
        </div>
        <div className='invest-pay-tc'>
          <p>I trust this correspondence finds you well. In furtherance of your funding transaction via UPI, we kindly request the submission of essential details for documentation purposes.</p>
          <p>To expedite the process, please provide the following information at your earliest convenience: </p>
          <li>Full Name of Sender</li>
          <li>Transaction ID (UPI)</li>
          <li>Contact Phone Number</li>
          <li>Email Address</li>
          <p>Kindly transmit the aforementioned details to sachink@infytechai.com. Upon receipt, our team will promptly compile the requisite investor file, tailored to your funding transaction.</p>
          <p>The finalized investor file will then be dispatched to the email address associated with this communication, ensuring secure and expeditious delivery.</p>
          <p>Should you have any inquiries or necessitate further clarification, do not hesitate to communicate with us directly. We appreciate your cooperation and look forward to facilitating a seamless and efficient funding process.</p>
          <p>Furthermore, please be advised that as a private firm, we are not accepting any form of donation. We appreciate your understanding and cooperation in this matter.</p>
        </div>
      </div>
      <Footer />
    </html>
  );
};

export default Investment;