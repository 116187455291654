import React, { useState } from 'react';

function CoursesGalleryBlock({ title, medias }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleGallery = () => {
    setIsOpen(!isOpen);
  };

  const chunkedMedia = [];

  let k = 0;
  for (let i = 0; i < medias.length; i++) {
    if ((i + 1) % 4 === 0) {
      chunkedMedia.push(medias.slice(k, k + 4));
      k = i + 1;
    }
    else if (i + 1 === medias.length && medias.length !== 4) {
      chunkedMedia.push(medias.slice(k, medias.length));
    }
  }

  return (
    <div className="media-gallery-block-co">
      <div className="block-title-co" onClick={toggleGallery}>
        {title}
      </div>
      {isOpen && (
        <div className="gallery-item-co">
          {chunkedMedia.map((group, index) => (
            <div className="gallery-row-co" key={index}>
              {group.map((media, i) => (
                <div key={i} className="media-container-co">
                  <div className="media-caption-co">{media.title}</div>
                  <iframe src={media.url} autoPlay allowFullScreen></iframe>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default CoursesGalleryBlock;