import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../Users/Home';
import Login from '../Users/Login';
import Register from '../Users/Register';
import RegisterationForm from '../Users/RegisterationForm';
import AboutInfytech from '../HomePages/AboutInfytech';
import AboutInfytechAI from '../HomePages/AboutInfytechAI';
import WhyInfytechAI from '../HomePages/WhyInfytechAI';
import TeamMaahaul from '../HomePages/TeamMaahaul';
import ContactUs from '../Users/ContactUs';
import PrivacyPolicy from '../HomePages/PrivacyPolicy';
import Terms from '../HomePages/Terms';
import Blog from '../HomePages/Blog';
import Faq from '../HomePages/Faq';
import FeedbackForm from '../HomePages/FeedbackForm';
import CommunityForm from '../HomePages/CommunityForm';
import Investment from '../HomePages/Investment';
import AdmissionForm from '../HomePages/OnlineAdmission';
import Phonepe from '../HomePages/Pay';
import RefundPolicy from '../HomePages/RefundPolicy';
import Success from '../Components/Success';
import Failure from '../Components/Failure';
import Gallery from '../HomePages/Gallery';
import Courses from '../HomePages/Courses';



const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/login" element={<Login />} />
    <Route path="/registerUser" element={<Register />} />
    <Route path="/register" element={<RegisterationForm />} />
    /** Home Pages */
    <Route path="/about" element={<AboutInfytech/>}/>
    <Route path="/aboutInfytechAI" element={<AboutInfytechAI/>}/>
    <Route path="/whyInfytechAI" element={<WhyInfytechAI/>}/>
    <Route path="/gallery" element={<Gallery/>}/>
    <Route path="/teamMaahaul" element={<TeamMaahaul/>}/>
    <Route path="/contact" element={<ContactUs/>}/>
    <Route path="/privacy" element={<PrivacyPolicy/>}/>
    <Route path="/terms" element={<Terms/>}/>
    <Route path="/refund" element={<RefundPolicy/>}/>
    <Route path="/blog" element={<CommunityForm/>}/>
    <Route path="/faq" element={<Faq/>}/>
    <Route path="/feedback" element={<FeedbackForm/>}/>
    <Route path="/investment" element={<Investment/>}/>
    <Route path="/admission" element={<AdmissionForm/>}/>
    <Route path="/pay" element={<Phonepe/>}/>
    <Route path="/success" element={<Success/>}/>
    <Route path="/failure" element={<Failure/>}/>
    <Route path="/courses" element={<Courses/>}/>
  </Routes>
);

export default AppRoutes;