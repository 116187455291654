import React from "react";
import Footer from '../Homes/Footer';
import Header from '../Homes/Header';
import '../HomePages/gallery.css'
import ImageGalleryBlock from "./ToggleGallery";

const Gallery = () => {
    // const title = "ITES"
    // const images = [
    //     {url:'https://infytechai.com/image-1.jpg', title:""},
    //     {url:'https://infytechai.com/image-2.jpg', title:""},
    //     {url:'https://infytechai.com/image-3.jpg', title:""},
    //     {url:'https://infytechai.com/image-4.jpg', title:""},
    //     {url:'https://infytechai.com/image-5.jpg', title:""}
    //   ];

    const titleImages = [
        {
            "Achievements": [
                { url: 'https://infytechai.com/image-1.jpg', title: "Pic1" },
                { url: 'https://infytechai.com/image-2.jpg', title: "Pic2" },
                { url: 'https://infytechai.com/image-3.jpg', title: "Pic3" },
                { url: 'https://infytechai.com/image-4.jpg', title: "Pic4" },
                { url: 'https://infytechai.com/image-5.jpg', title: "Pic5" }
            ]
        },
        {
            "Awards": [
                { url: 'https://infytechai.com/image-1.jpg', title: "" },
                { url: 'https://infytechai.com/image-2.jpg', title: "" },
                { url: 'https://infytechai.com/image-3.jpg', title: "" },
                { url: 'https://infytechai.com/image-4.jpg', title: "" },
                { url: 'https://infytechai.com/image-5.jpg', title: "" }
            ]
        },
        {
            "Sports": [
                { url: 'https://infytechai.com/image-1.jpg', title: "" },
                { url: 'https://infytechai.com/image-2.jpg', title: "" },
                { url: 'https://infytechai.com/image-3.jpg', title: "" },
                { url: 'https://infytechai.com/image-4.jpg', title: "" },
                { url: 'https://infytechai.com/image-5.jpg', title: "" }
            ]
        },
        {
            "Celebration": [
                { url: 'https://infytechai.com/image-1.jpg', title: "" },
                { url: 'https://infytechai.com/image-2.jpg', title: "" },
                { url: 'https://infytechai.com/image-3.jpg', title: "" },
                { url: 'https://infytechai.com/image-4.jpg', title: "" },
                { url: 'https://infytechai.com/image-5.jpg', title: "" }
            ]
        },
        {
            "Projects": [
                { url: 'https://infytechai.com/image-1.jpg', title: "" },
                { url: 'https://infytechai.com/image-2.jpg', title: "" },
                { url: 'https://infytechai.com/image-3.jpg', title: "" },
                { url: 'https://infytechai.com/image-4.jpg', title: "" },
                { url: 'https://infytechai.com/image-5.jpg', title: "" }
            ]
        },
        {
            "Innovations": [
                { url: 'https://infytechai.com/image-1.jpg', title: "" },
                { url: 'https://infytechai.com/image-2.jpg', title: "" },
                { url: 'https://infytechai.com/image-3.jpg', title: "" },
                { url: 'https://infytechai.com/image-4.jpg', title: "" },
                { url: 'https://infytechai.com/image-5.jpg', title: "" }
            ]
        },
        {
            "Mixed": [
                { url: 'https://infytechai.com/image-1.jpg', title: "" },
                { url: 'https://infytechai.com/image-2.jpg', title: "" },
                { url: 'https://infytechai.com/image-3.jpg', title: "" },
                { url: 'https://infytechai.com/image-4.jpg', title: "" },
                { url: 'https://infytechai.com/image-5.jpg', title: "" }
            ]
        }
    ];

    return (
        <html>
            <Header />
            <body className="project-body-gallery">
                <div id="main" className="project-main-container">
                    <div className="project-page_title">
                        <div className="title-container">
                            <h1 className="">Gallery</h1>
                        </div>
                    </div>
                </div>
                {titleImages.map((item, index) => (
                <ImageGalleryBlock title={Object.keys(item)[0]} images={item[Object.keys(item)]} />
                ))}
            </body>
            <Footer />
        </html>
    );
}


export default Gallery;