import React from "react";
import Footer from '../Homes/Footer';
import Header from '../Homes/Header';
import './refundPolicy.css'

const RefundPolicy = () => {
    return (
        <html>
            <Header />
            <body className="main-body-rfnd">
                <div><h1>Refund Policy</h1>
                    <p>Thank you for choosing our education platform for your educational  needs. We strive to provide high-quality services to our clients. Please read our refund policy carefully to understand your rights and obligations regarding refunds.</p>
                    <div></div>
                </div>
                <div><h2>Educational Services Refund Policy:</h2>
                    <div><h3>Course Fees:</h3></div>
                        <li>All payments made for educational courses are non-refundable</li>
                        <li>In the event of unforeseen circumstances or technical issues preventing access to the course content, we will make reasonable efforts to resolve the issue promptly.</li>
                    <div><h3>Cancellation by the Participant:</h3></div>
                        <li>If a participant wishes to withdraw from a course, no refunds will be issued.</li>
                        <li>Participants may be eligible to transfer their enrollment to a future course, subject to availability.</li>
                    <div><h3>Quality Assurance:</h3></div>
                        <li>We are committed to delivering high-quality educational content. If a participant is dissatisfied with the course, we encourage them to contact our support team to address their concerns.</li>
                </div>
            </body>
            <Footer />
        </html>
    );
}


export default RefundPolicy;