import React from "react";
import Footer from '../Homes/Footer';
import Header from '../Homes/Header';
import '../HomePages/courses.css'
import CoursesGalleryBlock from "./ToggleCourses";

const Courses = () => {

    const titleMedias = [
        {
            "Mathematics": [
                { url: 'https://www.youtube.com/embed/MyJFtIy2BqI', title: "media1" },
                { url: 'https://www.youtube.com/embed/MyJFtIy2BqI', title: "media2" },
                { url: 'https://www.youtube.com/embed/MyJFtIy2BqI', title: "media3" },
                { url: 'https://www.youtube.com/embed/MyJFtIy2BqI', title: "media4" },
                { url: 'https://www.youtube.com/embed/MyJFtIy2BqI', title: "media5" }
            ]
        },
        {
            "Science": [
                { url: 'https://www.youtube.com/embed/MyJFtIy2BqI', title: "media1" },
                { url: 'https://www.youtube.com/embed/MyJFtIy2BqI', title: "media2" },
                { url: 'https://www.youtube.com/embed/MyJFtIy2BqI', title: "media3" },
                { url: 'https://www.youtube.com/embed/MyJFtIy2BqI', title: "media4" },
                { url: 'https://www.youtube.com/embed/MyJFtIy2BqI', title: "media5" }
            ]
        },
        {
            "Aptitude & Reasoning": [
                { url: 'https://www.youtube.com/embed/MyJFtIy2BqI', title: "media1" },
                { url: 'https://www.youtube.com/embed/MyJFtIy2BqI', title: "media2" },
                { url: 'https://www.youtube.com/embed/MyJFtIy2BqI', title: "media3" },
                { url: 'https://www.youtube.com/embed/MyJFtIy2BqI', title: "media4" },
                { url: 'https://www.youtube.com/embed/MyJFtIy2BqI', title: "media5" }
            ]
        }
       
    ];

    return (
        <html>
            <Header />
            <body className="project-body-gallery-co">
                <div id="main" className="project-main-container-co">
                    <div className="project-page_title-co">
                        <div className="title-container-co">
                            <h1 className="">Courses</h1>
                        </div>
                    </div>
                </div>
                {titleMedias.map((item, index) => (
                    <CoursesGalleryBlock title={Object.keys(item)[0]} medias={item[Object.keys(item)]} />
                ))}
            </body>
            <Footer />
        </html>
    );
}


export default Courses;